var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || _vm.contactgroup)?_c('div',{staticClass:"c-block-list-contact-group-custom",attrs:{"id":_vm.$vnode.key}},[(_vm.title && _vm.includeInToc)?_c('LongReadTarget',{attrs:{"container-id":_vm.$vnode.key,"title":_vm.title}}):_vm._e(),_vm._v(" "),(_vm.title)?_c('BaseH2',{staticClass:"mb-xl",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.contactgroup)?_c('div',{staticClass:"flex flex-wrap gap-layout-gutter"},[(_vm.showPhoneSection)?_c('div',{class:[
				'bg-secondary py-xl',
				'flex flex-col justify-start',

				{
					'w-full px-lg >=1024:w-4/12col >=1024:px-xl':
						_vm.showMeetupSection || _vm.showMailSection,
					'w-full px-lg >=1024:w-8/12col >=1024:px-3xl':
						!_vm.showMeetupSection && !_vm.showMailSection,
				},
			]},[_c('div',[_c('div',{staticClass:"space-y-sm"},[_c('BaseH4',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s('Telefon')}}),_vm._v(" "),(_vm.contactgroup.phoneText)?_c('p',{domProps:{"textContent":_vm._s(_vm.contactgroup.phoneText)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"space-y-20 mt-xl"},[(_vm.contactgroup.phone)?_c('div',{staticClass:"flex space-x-md items-start"},[_c('SvgPhone',{staticClass:"w-16 mt-4"}),_vm._v(" "),_c('a',{staticClass:"u-link m-0",attrs:{"href":`tel:${_vm.contactgroup.phone}`},domProps:{"textContent":_vm._s(_vm.contactgroup.phone)}})],1):_vm._e(),_vm._v(" "),(
							_vm.contactgroup.phoneOpeningHours &&
							_vm.showPhoneOpeningHours
						)?_c('div',{staticClass:"flex items-start space-x-md"},[_c('SvgClock',{staticClass:"w-16 mt-4"}),_vm._v(" "),_c('div',{staticClass:"grid gap-x-20 gap-y-3",staticStyle:{"grid-template-columns":"1fr auto"}},[_vm._l((_vm.formatOpeningHours(
									_vm.contactgroup.phoneOpeningHours
								)),function(item,index){return [_c('div',{key:`phone-day-${index}`,domProps:{"textContent":_vm._s(item.day)}}),_vm._v(" "),_c('div',{key:`phone-hour-${index}`,staticClass:"text-right",domProps:{"textContent":_vm._s(item.hours)}})]})],2)],1):_vm._e()])]),_vm._v(" "),(_vm.contactgroup.phoneLink && _vm.contactgroup.phoneLink[0])?_c('NuxtLinkExt',{attrs:{"to":_vm.contactgroup.phoneLink[0].url,"target":_vm.contactgroup.phoneLink[0].target}},[_c('BaseButton',{staticClass:"mt-xl max-w-fit",attrs:{"tag":"div"},domProps:{"textContent":_vm._s('Book et telefonmøde')}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showMeetupSection)?_c('div',{class:[
				'bg-secondary bg-opacity-50',
				'flex flex-col justify-start',
				'py-xl',

				{
					'w-full px-lg >=1024:w-4/12col >=1024:px-xl':
						_vm.showPhoneSection || _vm.showMailSection,
					'w-full px-lg >=1024:w-8/12col >=1024:px-3xl':
						!_vm.showPhoneSection && !_vm.showMailSection,
				},
			]},[_c('div',[_c('div',{staticClass:"space-y-sm"},[_c('BaseH4',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s('Fremmøde')}}),_vm._v(" "),(_vm.contactgroup.meetText)?_c('p',{domProps:{"textContent":_vm._s(_vm.contactgroup.meetText)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"space-y-20 mt-xl"},[(_vm.contactgroup.meetAddress)?_c('div',{staticClass:"flex space-x-md items-start"},[_c('SvgPin',{staticClass:"w-16 mt-4"}),_vm._v(" "),_c('div',[_vm._l((_vm.contactgroup.meetAddress),function(adress,index){return [(index !== 0)?_c('br',{key:`meet-adress-break-${index}`}):_vm._e(),_vm._v(" "),_c('span',{key:`meet-adress-${index}`,domProps:{"textContent":_vm._s(adress)}})]})],2)],1):_vm._e(),_vm._v(" "),(
							_vm.contactgroup.meetOpeninghours &&
							_vm.showMeetOpeningHours
						)?_c('div',{staticClass:"flex items-start space-x-md"},[_c('SvgClock',{staticClass:"w-16 mt-4"}),_vm._v(" "),_c('div',{staticClass:"grid gap-x-20 gap-y-3",staticStyle:{"grid-template-columns":"1fr auto"}},[_vm._l((_vm.formatOpeningHours(
									_vm.contactgroup.meetOpeninghours
								)),function(item,index){return [_c('div',{key:`phone-day-${index}`,domProps:{"textContent":_vm._s(item.day)}}),_vm._v(" "),_c('div',{key:`phone-hour-${index}`,domProps:{"textContent":_vm._s(item.hours)}})]})],2)],1):_vm._e()])]),_vm._v(" "),(_vm.contactgroup.meetLink && _vm.contactgroup.meetLink[0])?_c('NuxtLinkExt',{attrs:{"to":_vm.contactgroup.meetLink[0].url,"target":_vm.contactgroup.meetLink[0].target}},[_c('BaseButton',{staticClass:"mt-xl max-w-fit",attrs:{"tag":"div"},domProps:{"textContent":_vm._s('Book et møde')}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.getMailSection)?_c('div',{class:[
				'bg-background py-xl flex-1',
				'flex flex-col gap-y-2xl',

				{
					'px-lg >=1024:px-xl':
						_vm.showPhoneSection || _vm.showMeetupSection,
					'px-lg >=1024:px-3xl':
						(!_vm.showPhoneSection && !_vm.showMeetupSection) ||
						(_vm.showPhoneSection && _vm.showMeetupSection),
				},
			]},[(
					_vm.contactgroup.safeMailLink &&
					_vm.contactgroup.safeMailLink[0]
				)?_c('div',{staticClass:"flex-1 flex flex-col h-full justify-start"},[_c('div',{staticClass:"space-y-sm"},[_c('BaseH4',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s('Send digital post')}}),_vm._v(" "),(_vm.contactgroup.safeMailText)?_c('p',{domProps:{"textContent":_vm._s(_vm.contactgroup.safeMailText)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-x-xs mt-md"},[_c('NuxtLinkExt',{attrs:{"to":_vm.contactgroup.safeMailLink[0].url}},[_c('BaseButton',{staticClass:"max-w-fit",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(
										_vm.contactgroup.safeMailLink[0].name ==
										_vm.contactgroup.safeMailLink[0].url
											? _vm.contactgroup.safeMailLink[0]
													.name
											: 'Digital post'
									)}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow',{staticClass:"transform -rotate-45"})]},proxy:true}],null,false,1300257900)})],1),_vm._v(" "),_c('div',{class:[
							'text-mit-id',
							'font-darker-grotesque font-medium',
						],domProps:{"textContent":_vm._s('Kræver MitID')}})],1)]):_vm._e(),_vm._v(" "),(
					_vm.contactgroup.safeMailLinkCompany &&
					_vm.contactgroup.safeMailLinkCompany[0]
				)?_c('div',{staticClass:"flex-1 flex flex-col h-full justify-between"},[_c('div',{staticClass:"space-y-sm"},[_c('BaseH4',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s('Erhverv: Send digital post')}}),_vm._v(" "),(_vm.contactgroup.safeMailTextCompany)?_c('p',{domProps:{"textContent":_vm._s(_vm.contactgroup.safeMailTextCompany)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-x-xs mt-md"},[_c('NuxtLinkExt',{attrs:{"to":_vm.contactgroup.safeMailLinkCompany[0].url}},[_c('BaseButton',{staticClass:"max-w-fit",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(
										_vm.contactgroup.safeMailLinkCompany[0]
											.name ==
										_vm.contactgroup.safeMailLinkCompany[0]
											.url
											? _vm.contactgroup
													.safeMailLinkCompany[0]
													.name
											: 'Digital post'
									)}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow',{staticClass:"transform -rotate-45"})]},proxy:true}],null,false,3815941288)})],1),_vm._v(" "),_c('div',{class:[
							'text-mit-id',
							'font-darker-grotesque font-medium',
						],domProps:{"textContent":_vm._s('Kræver MitID')}})],1)]):_vm._e(),_vm._v(" "),(_vm.contactgroup.mailEmail)?_c('div',{staticClass:"flex-1 flex flex-col h-full justify-between"},[_c('div',{staticClass:"space-y-sm"},[_c('BaseH4',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s('Send almindelig mail')}}),_vm._v(" "),(_vm.contactgroup.mailText)?_c('p',{domProps:{"textContent":_vm._s(_vm.contactgroup.mailText)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start gap-x-xs gap-y-xs mt-md"},[_c('NuxtLinkExt',{attrs:{"to":`mailto:${_vm.contactgroup.mailEmail}`}},[_c('BaseButton',{staticClass:"max-w-fit",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Almindelig mail")]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow',{staticClass:"transform -rotate-45"})]},proxy:true}],null,false,1733192152)})],1),_vm._v(" "),(
							_vm.contactgroup.mailLink &&
							_vm.contactgroup.mailLink[0]
						)?_c('NuxtLinkExt',{attrs:{"to":_vm.contactgroup.mailLink[0].url,"target":_vm.contactgroup.mailLink[0].target}},[_c('BaseButton',{staticClass:"max-w-fit",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.contactgroup.mailLink[0].name))]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow',{staticClass:"transform -rotate-45"})]},proxy:true}],null,false,277100479)})],1):_vm._e()],1)]):_vm._e()]):_vm._e()]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }