<template>
	<div
		v-if="title || contactgroup"
		:id="$vnode.key"
		class="c-block-list-contact-group-custom"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<BaseH2 v-if="title" tag="h2" class="mb-xl" v-text="title" />

		<div v-if="contactgroup" class="flex flex-wrap gap-layout-gutter">
			<!-- Phone -->
			<div
				v-if="showPhoneSection"
				:class="[
					'bg-secondary py-xl',
					'flex flex-col justify-start',

					{
						'w-full px-lg >=1024:w-4/12col >=1024:px-xl':
							showMeetupSection || showMailSection,
						'w-full px-lg >=1024:w-8/12col >=1024:px-3xl':
							!showMeetupSection && !showMailSection,
					},
				]"
			>
				<div>
					<div class="space-y-sm">
						<BaseH4 tag="h3" v-text="'Telefon'" />
						<p
							v-if="contactgroup.phoneText"
							v-text="contactgroup.phoneText"
						></p>
					</div>

					<div class="space-y-20 mt-xl">
						<div
							v-if="contactgroup.phone"
							class="flex space-x-md items-start"
						>
							<SvgPhone class="w-16 mt-4" />

							<a
								class="u-link m-0"
								:href="`tel:${contactgroup.phone}`"
								v-text="contactgroup.phone"
							></a>
						</div>

						<div
							v-if="
								contactgroup.phoneOpeningHours &&
								showPhoneOpeningHours
							"
							class="flex items-start space-x-md"
						>
							<SvgClock class="w-16 mt-4" />

							<div
								class="grid gap-x-20 gap-y-3"
								style="grid-template-columns: 1fr auto"
							>
								<template
									v-for="(item, index) in formatOpeningHours(
										contactgroup.phoneOpeningHours
									)"
								>
									<div
										:key="`phone-day-${index}`"
										v-text="item.day"
									></div>
									<div
										:key="`phone-hour-${index}`"
										class="text-right"
										v-text="item.hours"
									></div>
								</template>
							</div>
						</div>
					</div>
				</div>

				<NuxtLinkExt
					v-if="contactgroup.phoneLink && contactgroup.phoneLink[0]"
					:to="contactgroup.phoneLink[0].url"
					:target="contactgroup.phoneLink[0].target"
				>
					<BaseButton
						tag="div"
						class="mt-xl max-w-fit"
						v-text="'Book et telefonmøde'"
					/>
				</NuxtLinkExt>
			</div>

			<!-- Meetup -->
			<div
				v-if="showMeetupSection"
				:class="[
					'bg-secondary bg-opacity-50',
					'flex flex-col justify-start',
					'py-xl',

					{
						'w-full px-lg >=1024:w-4/12col >=1024:px-xl':
							showPhoneSection || showMailSection,
						'w-full px-lg >=1024:w-8/12col >=1024:px-3xl':
							!showPhoneSection && !showMailSection,
					},
				]"
			>
				<div>
					<div class="space-y-sm">
						<BaseH4 tag="h3" v-text="'Fremmøde'" />

						<p
							v-if="contactgroup.meetText"
							v-text="contactgroup.meetText"
						></p>
					</div>

					<div class="space-y-20 mt-xl">
						<div
							v-if="contactgroup.meetAddress"
							class="flex space-x-md items-start"
						>
							<SvgPin class="w-16 mt-4" />

							<div>
								<template
									v-for="(
										adress, index
									) in contactgroup.meetAddress"
								>
									<br
										v-if="index !== 0"
										:key="`meet-adress-break-${index}`"
									/>
									<span
										:key="`meet-adress-${index}`"
										v-text="adress"
									></span>
								</template>
							</div>
						</div>

						<div
							v-if="
								contactgroup.meetOpeninghours &&
								showMeetOpeningHours
							"
							class="flex items-start space-x-md"
						>
							<SvgClock class="w-16 mt-4" />

							<div
								class="grid gap-x-20 gap-y-3"
								style="grid-template-columns: 1fr auto"
							>
								<template
									v-for="(item, index) in formatOpeningHours(
										contactgroup.meetOpeninghours
									)"
								>
									<div
										:key="`phone-day-${index}`"
										v-text="item.day"
									></div>
									<div
										:key="`phone-hour-${index}`"
										v-text="item.hours"
									></div>
								</template>
							</div>
						</div>
					</div>
				</div>

				<NuxtLinkExt
					v-if="contactgroup.meetLink && contactgroup.meetLink[0]"
					:to="contactgroup.meetLink[0].url"
					:target="contactgroup.meetLink[0].target"
				>
					<BaseButton
						tag="div"
						class="mt-xl max-w-fit"
						v-text="'Book et møde'"
					/>
				</NuxtLinkExt>
			</div>

			<!-- Mail -->
			<div
				v-if="getMailSection"
				:class="[
					'bg-background py-xl flex-1',
					'flex flex-col gap-y-2xl',

					{
						'px-lg >=1024:px-xl':
							showPhoneSection || showMeetupSection,
						'px-lg >=1024:px-3xl':
							(!showPhoneSection && !showMeetupSection) ||
							(showPhoneSection && showMeetupSection),
					},
				]"
			>
				<div
					v-if="
						contactgroup.safeMailLink &&
						contactgroup.safeMailLink[0]
					"
					class="flex-1 flex flex-col h-full justify-start"
				>
					<div class="space-y-sm">
						<BaseH4 tag="h3" v-text="'Send digital post'" />
						<p
							v-if="contactgroup.safeMailText"
							v-text="contactgroup.safeMailText"
						></p>
					</div>

					<div class="flex items-center gap-x-xs mt-md">
						<NuxtLinkExt :to="contactgroup.safeMailLink[0].url">
							<BaseButton tag="div" class="max-w-fit">
								<template #default
									><span
										v-text="
											contactgroup.safeMailLink[0].name ==
											contactgroup.safeMailLink[0].url
												? contactgroup.safeMailLink[0]
														.name
												: 'Digital post'
										"
									></span
								></template>
								<template #icon>
									<SvgArrow class="transform -rotate-45" />
								</template>
							</BaseButton>
						</NuxtLinkExt>

						<div
							:class="[
								'text-mit-id',
								'font-darker-grotesque font-medium',
							]"
							v-text="'Kræver MitID'"
						></div>
					</div>
				</div>

				<div
					v-if="
						contactgroup.safeMailLinkCompany &&
						contactgroup.safeMailLinkCompany[0]
					"
					class="flex-1 flex flex-col h-full justify-between"
				>
					<div class="space-y-sm">
						<BaseH4
							tag="h3"
							v-text="'Erhverv: Send digital post'"
						/>
						<p
							v-if="contactgroup.safeMailTextCompany"
							v-text="contactgroup.safeMailTextCompany"
						></p>
					</div>

					<div class="flex items-center gap-x-xs mt-md">
						<NuxtLinkExt
							:to="contactgroup.safeMailLinkCompany[0].url"
						>
							<BaseButton tag="div" class="max-w-fit">
								<template #default
									><span
										v-text="
											contactgroup.safeMailLinkCompany[0]
												.name ==
											contactgroup.safeMailLinkCompany[0]
												.url
												? contactgroup
														.safeMailLinkCompany[0]
														.name
												: 'Digital post'
										"
									></span
								></template>
								<template #icon>
									<SvgArrow class="transform -rotate-45" />
								</template>
							</BaseButton>
						</NuxtLinkExt>

						<div
							:class="[
								'text-mit-id',
								'font-darker-grotesque font-medium',
							]"
							v-text="'Kræver MitID'"
						></div>
					</div>
				</div>

				<div
					v-if="contactgroup.mailEmail"
					class="flex-1 flex flex-col h-full justify-between"
				>
					<div class="space-y-sm">
						<BaseH4 tag="h3" v-text="'Send almindelig mail'" />
						<p
							v-if="contactgroup.mailText"
							v-text="contactgroup.mailText"
						></p>
					</div>

					<div
						class="
							flex flex-col
							items-start
							gap-x-xs gap-y-xs
							mt-md
						"
					>
						<NuxtLinkExt :to="`mailto:${contactgroup.mailEmail}`">
							<BaseButton tag="div" class="max-w-fit">
								<template #default>Almindelig mail</template>
								<template #icon>
									<SvgArrow class="transform -rotate-45" />
								</template>
							</BaseButton>
						</NuxtLinkExt>
						<NuxtLinkExt
							v-if="
								contactgroup.mailLink &&
								contactgroup.mailLink[0]
							"
							:to="contactgroup.mailLink[0].url"
							:target="contactgroup.mailLink[0].target"
						>
							<BaseButton tag="div" class="max-w-fit">
								<template #default>{{
									contactgroup.mailLink[0].name
								}}</template>
								<template #icon>
									<SvgArrow class="transform -rotate-45" />
								</template>
							</BaseButton>
						</NuxtLinkExt>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgClock from '~/assets/svgs/icon-clock.svg?inline';
import SvgPhone from '~/assets/svgs/icon-phone.svg?inline';
import SvgPin from '~/assets/svgs/icon-pin.svg?inline';

export default {
	name: 'BlockListContactGroupCustom',
	components: { LongReadTarget, SvgArrow, SvgClock, SvgPhone, SvgPin },
	inheritAttrs: false,

	props: {
		title: String,
		includeInToc: Boolean,
		contactgroup: Object,
		showPhoneSection: Boolean,
		showMeetupSection: Boolean,
		showMailSection: Boolean,
	},

	computed: {
		showMeetOpeningHours() {
			return this.contactgroup.meetOpeninghours.reduce(
				(acc, cur) => acc || cur.isOpen,
				false
			);
		},

		showPhoneOpeningHours() {
			return this.contactgroup.phoneOpeningHours.reduce(
				(acc, cur) => acc || cur.isOpen,
				false
			);
		},
		getMailSection() {
			return (
				this.showMailSection &&
				((this.contactgroup.safeMailLink &&
					this.contactgroup.safeMailLink[0]) ||
					(this.contactgroup.safeMailLinkCompany &&
						this.contactgroup.safeMailLinkCompany[0]) ||
					this.contactgroup.mailEmail)
			);
		},
	},

	methods: {
		formatOpeningHours(hours) {
			return hours
				?.filter((day) => day.isOpen && day.hoursOfBusiness.length)
				.map((day) => {
					const response = { day: day.dayOfTheWeek, hours: [] };

					day.hoursOfBusiness.forEach((hour) => {
						const opensAt = new Date(hour.opensAt);
						const closesAt = new Date(hour.closesAt);
						const opensAtStr = `${opensAt.getHours()}.${
							(opensAt.getMinutes() < 10 ? '0' : '') +
							opensAt.getMinutes()
						}`;
						const closesAtStr = `${closesAt.getHours()}.${
							(closesAt.getMinutes() < 10 ? '0' : '') +
							closesAt.getMinutes()
						}`;
						response.hours.push(`kl. ${opensAtStr}-${closesAtStr}`);
					});

					response.hours = response.hours.join(' og ');
					return response;
				});
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-contact-group-custom .c-base-button__inner {
	@apply min-w-max;
}
</style>
